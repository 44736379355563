<template>
  <TTView>
    <VRow>
      <VCol>
        <TopicForm
          :entity="topic"
          :loading="loading"
          @update:name="topic.name = $event"
          @cancel="handleCancel"
          @submit="handleSubmit"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import TopicForm from '../../../components/multi-level-test/topics/TopicForm.vue';

export default {
  name: 'TestsTopicsEdit',
  components: {
    TopicForm,
  },
  inject: ['Names'],
  data() {
    return {
      loading: false,
      topic: {},
    };
  },
  computed: {
    testId() {
      return this.$route.params.testId;
    },
    topicId() {
      return this.$route.params.topicId;
    },
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;

        const { topicId } = this;
        const data = { id: topicId };

        const { topic } = await this.$di.api.MultiLevelTest.TopicsById({ data });

        this.topic = topic;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async handleSubmit() {
      try {
        this.loading = true;

        const { topic, topicId } = this;
        const data = { id: topicId, topic: { ...topic } };

        await this.$di.api.MultiLevelTest.TopicsUpdate({ data });

        this.$router.push({ name: this.Names.R_MULTI_LEVEL_TEST_TESTS_TOPICS_INDEX, params: { testId: this.testId } });
        this.$di.notify.snackSuccess('Топик обновлен');
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    handleCancel() {
      this.$router.push({ name: this.Names.R_MULTI_LEVEL_TEST_TESTS_TOPICS_INDEX, params: { testId: this.testId } });
    },
  },
};
</script>
